/** @format */

// https://vuex.vuejs.org/en/state.html
import config from '@/config'

export default {
  authStatus: '',
  token: localStorage.getItem('token') || '',
  tokenRefreshed: false,
  refreshToken: '',
  user: {},
  profileSettings: {},
  tableList: [],
  error_msg: '',
  notifications: [],
  currentTime: new Date(),
  // @ts-ignore

  avatarURL: require('@/assets/img/avatar.png'),

  // PAAntrag
  PAMajorList: [],
  PAPLList: [],
  PAMainTopics: [],
  PAModulList: [],
  POs: [],
  PrueferList: [],
  PersonList: [],
  PAAntragsvorlagen: [],

  stundenplanList: [],
  LVList: [],
  eventList: [],
  courseSelectionList: [],

  // ModulAdmin
  pruefungsformList: [],
  bewertungsschemaList: [],
  pruefungsformTemplateList: [],
  languageList: [],
  fachcodeList: [],
  lehrformList: [],
  semesterList: [],
  studiengangList: [],
  POModulList: [],
  pruefer: [],
  dokumentvorlagengruppeList: [],
  dokumentvorlageList: [],

  loadingStatus: false,
  preventUnload: false,
  currentSemesterInfo: {},
  nextSemesterInfo: {},

  // Sidebar
  Sidebar_drawer: config.Sidebar_drawer,
  mini_sidebar: config.mini_sidebar,

  clearingFieldList: [],
  clearingModulListStart: {},
  updatingStatus: {},
  hoverStatus: {},
  clearingNavList: [],

  showAppBar: true,
  breadcrumbItems: [],

  snackBarOptions: {},
  overlayOptions: {},
  _filterOptions: {
    Clearingeintrag: {
    },
  },

  hightlightedClearingeintragId: null,

  extraTitleInfo: '',

  ticketMetaInfo: null,

  packageVersion: process.env.VUE_APP_VERSION || '0',
}
