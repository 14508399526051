/** @format */

// https://vuex.vuejs.org/en/getters.html

export default {
  authorized: state => !!state.token,
  authstatus: state => state.authStatus,
  tokenRefreshed: state => state.tokenRefreshed,
  notifications: state => state.notifications,
  getNotifHeader: state => state.currentTime,
  getAvatar: state => state.avatarURL,
  getUser: state => state.user,
  PAAntragsvorlagen: state => state.PAAntragsvorlagen,
  PAMajorList: state => state.PAMajorList,
  getPrueferList: state => state.PrueferList,
  getPersonList: state => state.PersonList,
  PAPLList: state => state.PAPLList,
  PAMainTopics: state => state.PAMainTopics,
  PAModulList: state => state.PAModulList,
  getPOs: state => state.POs,
  profileSettings: state => state.profileSettings,
  stundenplanList: state => state.stundenplanList,
  getPruefungsformList: state => state.pruefungsformList,
  getBewertungsschemaList: state => state.bewertungsschemaList,
  getPruefungsformTemplateList: state => state.pruefungsformTemplateList,
  getLanguageList: state => state.languageList,
  getFachcodeList: state => state.fachcodeList,
  getLehrformList: state => state.lehrformList,
  getSemesterList: state => state.semesterList,
  getStudiengangList: state => state.studiengangList,
  POModulList: state => state.POModulList,
  loadingStatus: state => state.loadingStatus,
  preventUnload: state => state.preventUnload,
  currentSemesterInfo: state => state.currentSemesterInfo,
  nextSemesterInfo: state => state.nextSemesterInfo,
  appVersion: state => state.packageVersion,
  Sidebar_drawer: state => state.Sidebar_drawer,
  mini_sidebar: state => state.mini_sidebar,
  poList: state => state.poList?.data,
  clearingeintragList: state => state.clearingeintragList?.data,
  clearingeintragListStatus: state => state.statusClearingeintragList,
  clearingFieldList: state => state.clearingFieldList,
  updatingStatus: state => state.updatingStatus,
  hoverStatus: state => state.hoverStatus,
  clearingNavList: state => state.clearingNavList,
  breadcrumbItems: state => state.breadcrumbItems,
  clearingModulListStart: state => state.clearingModulListStart,
  showAppBar: state => state.showAppBar,
  snackBarOptions: state => state.snackBarOptions,
  overlayOptions: state => state.overlayOptions,
  _filterOptions: state => state._filterOptions,
  hightlightedClearingeintragId: state => state.hightlightedClearingeintragId,
  LVList: state => state.LVList,
  eventList: state => state.eventList,
  courseSelectionList: state => state.courseSelectionList,
  extraTitleInfo: state => state.extraTitleInfo,
  ticketMetaInfo: state => state.ticketMetaInfo,
  dokumentvorlagengruppeList: state => state.dokumentvorlagengruppeList?.data,
  dokumentvorlageList: state => state.dokumentvorlageList?.data,
}
