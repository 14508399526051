/** @format */

import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'

import { createVuetify } from 'vuetify'

import {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCheckboxBtn,
  VChip,
  VChipGroup,
  VCol,
  VContainer,
  VDataIterator,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpandTransition,
  VExpansionPanel,
  VExpansionPanelText,
  VExpansionPanelTitle,
  VExpansionPanels,
  VFadeTransition,
  VFileInput,
  VFooter,
  VForm,
  VHover,
  VIcon,
  VImg,
  VLabel,
  VLayout,
  VList,
  VListItem,
  VListItemAction,
  VListItemSubtitle,
  VListItemTitle,
  VListSubheader,
  VMain,
  VMenu,
  VNavigationDrawer,
  VOverlay,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VScaleTransition,
  VSelect,
  VSheet,
  VSkeletonLoader,
  VSlider,
  VSnackbar,
  VSpacer,
  VStepper,
  VStepperActions,
  VStepperHeader,
  VStepperItem,
  VStepperWindow,
  VStepperWindowItem,
  VSwitch,
  VTab,
  VTable,
  VTabs,
  VTextField,
  VTextarea,
  VThemeProvider,
  VTimeline,
  VTimelineItem,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip,
  VVirtualScroll,
  VWindow,
  VWindowItem,
} from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { LightTheme } from '@/theme/LightTheme'
import { DarkTheme } from '@/theme/DarkTheme'

import 'vuetify/styles'

export default createVuetify({
  components: {
    VAlert,
    VAutocomplete,
    VApp,
    VAppBar,
    VBreadcrumbsItem,
    VBtnToggle,
    VSelect,
    VAppBarNavIcon,
    VAvatar,
    VBadge,
    VBtn,
    VBreadcrumbs,
    VExpansionPanels,
    VDataIterator,
    VExpansionPanel,
    VExpansionPanelText,
    VCard,
    VExpansionPanelTitle,
    VCardActions,
    VCardText,
    VCardTitle,
    VSlider,
    VCardSubtitle,
    VChip,
    VChipGroup,
    VFadeTransition,
    VCheckbox,
    VCheckboxBtn,
    VCol,
    VContainer,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VExpandTransition,
    VFileInput,
    VFooter,
    VForm,
    VHover,
    VIcon,
    VImg,
    VLabel,
    VLayout,
    VList,
    VListItem,
    VListItemAction,
    VListItemSubtitle,
    VListItemTitle,
    VListSubheader,
    VMain,
    VMenu,
    VNavigationDrawer,
    VOverlay,
    VProgressCircular,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    VRow,
    VScaleTransition,
    VSheet,
    VSkeletonLoader,
    VSnackbar,
    VSpacer,
    VSwitch,
    VStepper,
    VStepperActions,
    VStepperHeader,
    VStepperItem,
    VStepperWindow,
    VStepperWindowItem,
    VTab,
    VTabs,
    VTable,
    VTextField,
    VTextarea,
    VThemeProvider,
    VTimeline,
    VTimelineItem,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTooltip,
    VVirtualScroll,
    VWindow,
    VWindowItem,
  },
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
  theme: {
    defaultTheme: 'LightTheme',
    themes: {
      LightTheme,
      DarkTheme,
    },
  },
  defaults: {
    icons: {
      defaultSet: 'mdi',
    },
    VBtn: {
    },
    VCard: {
      rounded: 'md',
    },
    VTextField: {
      rounded: 'lg',
    },
    VTooltip: {
      location: 'top',
    },
  },

})
